import { Injectable, Inject, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { KeyValue } from "@angular/common";
import { map, tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from './config.service';
import { RSICitizen } from '../_models/rsiCitizen';
import { LoginResult } from '../_models/loginResult';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SCTMApiService {

  public loginResult: LoginResult;
  tokenDate: Date;

  // Globals
  public apiUrl: string;
  public userId: string;

  private discordCode: string;
  private email: string;

  @Output() rsiCitizen: EventEmitter<RSICitizen> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private cfg: ConfigService,
    private toastr: ToastrService,
    private token: TokenStorageService
  ) {
    this.apiUrl = cfg.apiUrl;

    if (token.getToken() != null) {
      const _decodedToken = this.token.helper.decodeToken(token.getToken());
      this.rsiCitizen_GET(_decodedToken["custom:rsiHandle"], null, null).toPromise().then(respRSICitizen => {
        this.rsiCitizen.emit(respRSICitizen);
      });
    }
  }

  generateClientIdentifier(): number {
    return Math.floor((Math.random() * 1000000000) + 1);
  }

  apiCall_GET(url: string, queryValues: KeyValue<string, any>[], signalR: string, clientIdentifier: number, showAuthError: boolean = false): Observable<any> {

    let _url: string = url;

    if (_url.trim().toLowerCase().startsWith("http")) {
      // FQDN, no needed apiUrl
    }
    else if (_url.trim().toLowerCase().startsWith("/")) {
      // relative path
      _url = this.apiUrl + _url;
    } else {
      // relative path without /
      _url = this.apiUrl + "/" + _url;
    }

    const _queryValues: KeyValue<string, any>[] = queryValues || [];

    if (signalR !== null) _queryValues.push({ key: 'signalR', value: signalR });
    if (clientIdentifier !== null) _queryValues.push({ key: 'clientIdentifier', value: clientIdentifier });

    for (let item of _queryValues) {
      _url += (_url.includes("?")) ? "&" : "?";
      _url += `${item.key}=${item.value}`;
    }

    console.log("making GET call to: " + _url);

    return this.http.get(_url)
      .pipe(
        catchError((error) => {
          console.log(error);

          return throwError(error);

        })
      );
  }

  apiCall_POST(url: string, queryValues: KeyValue<string, any>[], model: any, signalR: string, clientIdentifier: number): Observable<any> {

    var _url: string = url;

    if (_url.trim().toLowerCase().startsWith("http")) {
      // FQDN, no needed apiUrl
    }
    else if (_url.trim().toLowerCase().startsWith("/")) {
      // relative path
      _url = this.apiUrl + _url;
    } else {
      // relative path without /
      _url = this.apiUrl + "/" + _url;
    }

    var _queryValues: KeyValue<string, any>[] = queryValues || [];

    if (signalR != null) _queryValues.push({ key: 'signalR', value: signalR });
    if (clientIdentifier != null) _queryValues.push({ key: 'clientIdentifier', value: clientIdentifier });

    for (let item of _queryValues) {
      _url += (_url.includes("?")) ? "&" : "?";
      _url += `${item.key}=${item.value}`;
    }

    const headers = new HttpHeaders().set("content-type", "application/json");

    console.log("making POST call to: " + _url);
    if (model != null) {
      console.log("passing model");
      console.log(model);
    }

    return this.http.post<any>(_url, model, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);

          return throwError(error);

        })
      );
  }

  rsiCitizen_GET(rsiHandle: string, signalR: string, clientIdentifier: number): Observable<RSICitizen> {

    const _url = 'data/citizens/' + rsiHandle.trim();
    return this.apiCall_GET(_url, null, signalR, clientIdentifier);
  }

  Register_POST(email: string, password: string, rsiHandle: string, signalR: string, clientIdentifier: number): Observable<any> {
    const _url = "/auth/register";
    console.log("Register_POST to: " + _url);

    const model: any = {
      "email": email.trim(),
      "password": password,
      "rsiHandle": rsiHandle.trim()
    };

    return this.apiCall_POST(_url, null, model, signalR, clientIdentifier);
  }

  Verify_POST(email: string, signalR: string, clientIdentifier: number): Observable<any> {
    const _url = "/auth/confirm";
    console.log("Register_POST to: " + _url);

    const model: any = {
      "email": email.trim()
    };

    return this.apiCall_POST(_url, null, model, signalR, clientIdentifier);
  }

  Login_POST(email: string, password: string, signalR: string, clientIdentifier: number): Observable<any> {
    const _url = "/auth/login";
    console.log("Register_POST to: " + _url);

    const model: any = {
      "email": email.trim(),
      "password": password
    };

    return this.apiCall_POST(_url, null, model, signalR, clientIdentifier)
      .pipe(
        tap((response: LoginResult) => {
          this.loginResult = response;
          this.tokenDate = new Date();

          const _decodedToken = this.token.helper.decodeToken(this.loginResult.jwt);
          this.discordCode = _decodedToken["custom:discord"];

          this.rsiCitizen_GET(_decodedToken["custom:rsiHandle"], null, null).toPromise().then(respRSICitizen => {
            this.rsiCitizen.emit(respRSICitizen);
          });
        })
      )

  }

  isLoggedIn(): boolean {
    console.log('----');
    return this.token.isValid();
  }

  GetDiscordCode(): string {
    if (this.token === null || this.token.getToken() === null) {
      console.error("Unable to read token");
      return null;
    }
    else {
      const _decodedToken = this.token.helper.decodeToken(this.token.getToken());

      console.log("token read");
      this.discordCode = _decodedToken["custom:discord"];
      //console.log("discord code: " + this.discordCode);
      return this.discordCode;

    }
  }

  GetEmail(): string {
    if (this.token === null || this.token.getToken() === null) {
      console.error("Unable to read email");
      return null;
    }
    else {
      const _decodedToken = this.token.helper.decodeToken(this.token.getToken());

      console.log("token read");
      this.email = _decodedToken["email"];
      //console.log("discord code: " + this.discordCode);
      return this.email;

    }
  }
}
