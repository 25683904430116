<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-6">
      <div class="card" *ngIf="true">
        <div class="card-body">
          <h4 class="card-title">Welcome to SCTradeMasters!</h4>
          <p>
            <strong><em>Ready to join our leaderboards?</em></strong> - Link your Discord Account now!
          </p>

          <div class="accordion" id="accordion1">

            <!-- Card - SCTM Discord Server -->
            <div class="card">
              <div class="card-header">
                <h6>
                  <a class="collapse" href="#collapseOne" data-toggle="collapse">
                    Option1: Use SCTradeMasters Discord server
                  </a>
                </h6>
              </div>
              <div class="collapse" id="collapseOne" data-parent="#accordion1">
                <div class="card-body">
                  <div>
                    <ul class="list-group">
                      <li class="list-group-item bg-secondary">
                        <h6>1. Join the SCTradeMasters Discord Server</h6>
                        <iframe src="https://discordapp.com/widget?id=475101141193981953&theme=dark" width="350" height="200" allowtransparency="true" frameborder="0"></iframe>
                      </li>
                      <li class="list-group-item bg-secondary">
                        2. Open up a Direct Message with ChrispyKoala using the command:<br />
                        <p class="user-select-all text-danger mt-3 p-3 bg-white" style="border:1px solid #bbb">
                          ~roo chat
                        </p>
                      </li>
                      <li class="list-group-item bg-secondary">
                        3. ChrispyKangaroo should have messaged you. In the chat with him, enter the command below:<br />
                        <p class="user-select-all text-danger mt-3 p-3 bg-white" style="border:1px solid #bbb">
                          ~roo hello {{email}} {{discordCode}}
                        </p>
                      </li>
                    </ul>


                  </div>
                </div>
              </div>

              <!-- Card - Chat with the BOT -->
              <div class="card">
                <div class="card-header">
                  <h6>
                    <a href="#collapseTwo" data-toggle="collapse">
                      Option2: Use ChrispyKangaroo on your server
                    </a>
                  </h6>
                </div>
                <div class="collapse " id="collapseTwo" data-parent="#accordion1">
                  <div class="card-body">
                    <div>
                      <p class="text-muted text-sm">
                        We get it. You're a member of too many servers and there's no room in your heart for us.
                        If ChrispyKangaroo is running on your existing server, her's all you need to do:
                      </p>
                      <ul class="list-group">
                        <li class="list-group-item bg-secondary">
                          1. Find a channel on your server where ChrispyKangaroo is a member.<br />
                          <img src="./assets/images/ChrispyKangaroo-bot.png" alt="ChrispyKangaroo Bot" class="img-fluid mt-3" />
                        </li>
                        <li class="list-group-item bg-secondary">
                          2. Open up a Direct Message with ChrispyKoala using the command:<br />
                          <p class="user-select-all text-danger mt-3 p-3 bg-white" style="border:1px solid #bbb">
                            ~roo chat
                          </p>
                        </li>
                        <li class="list-group-item bg-secondary">
                          3. ChrispyKangaroo should have messaged you. In the chat with him, enter the command below:<br />
                          <p class="user-select-all text-danger mt-3 p-3 bg-white" style="border:1px solid #bbb">
                            ~roo hello {{email}} {{discordCode}}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
