import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USER_EMAIL = 'auth-email';
const USER_RSIHANDLE = 'auth-rsiHandle';
const GROUPS = 'auth-groups';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  helper = new JwtHelperService();

  constructor() { }

  signOut() {
    localStorage.clear();
  }

  public saveToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
    
    const _decodedToken = this.helper.decodeToken(token);
    console.log(_decodedToken);
    localStorage.setItem(USER_EMAIL, _decodedToken.email);
    localStorage.setItem(USER_RSIHANDLE, _decodedToken['custom:rsiHandle']);
    localStorage.setItem(GROUPS, _decodedToken['cognito:groups']);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public isValid(): boolean {
    console.log("Have token? " + (localStorage.getItem(TOKEN_KEY) != null));
    console.log("valid token? " + !this.helper.isTokenExpired(localStorage.getItem(TOKEN_KEY)));
    return (localStorage.getItem(TOKEN_KEY) != null && !this.helper.isTokenExpired(localStorage.getItem(TOKEN_KEY)));
  }
}
