import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SCTMApiService } from '../../../_services/sctmApi.service';
import { RSICitizen } from '../../../_models/rsiCitizen';

@Component({
  selector: 'app-rsi-handle',
  templateUrl: './rsi-handle.component.html',
  styleUrls: ['./rsi-handle.component.scss']
})
export class RsiHandleComponent implements OnInit {

  @Output() rsiHandleFound: EventEmitter<RSICitizen> = new EventEmitter();

  isLoading: boolean = false;

  rsiHandleInput: string;
  rsiCitizenResult: RSICitizen;

  constructor(private api: SCTMApiService) { }

  ngOnInit(): void {
  }

  getRSIProfile() {
    this.isLoading = true;

    this.api.rsiCitizen_GET(this.rsiHandleInput, null, null).toPromise().then(response => {
      console.log('citizen received -------');
      console.log(response);
      this.rsiCitizenResult = response;
      this.rsiHandleFound.emit(this.rsiCitizenResult);
    }).finally(() => { this.isLoading = false; });
  }

}
