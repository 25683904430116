import { Component } from '@angular/core';
import { version } from '../../package.json';
import { RSICitizen } from './_models/rsiCitizen';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web-app';
  public version: string = version;

  public rsiCitizen: RSICitizen;

}
