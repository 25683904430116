import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leaderboards-trade-page',
  templateUrl: './leaderboards-trade-page.component.html',
  styleUrls: ['./leaderboards-trade-page.component.scss']
})
export class LeaderboardsTradePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
