<!-- Page Title-->
<div class="page-title d-flex" aria-label="Page title" style="background-image: url(./assets/thm/img/page-title/shop-pattern.jpg);">
  <div class="container text-left align-self-center">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="./">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a href="account-orders.html">Account</a>
        </li>
      </ol>
    </nav>
    <h1 class="page-title-heading">Register</h1>
  </div>
</div>
<!-- Page Content-->
<div class="container mb-3">
  <div class="row justify-content-center">

    <div id="registerForm" class="col-md-6 pb-5">

      <div class="wizard">
        <div class="wizard-body pt-2">
          <!-- Priority Registration -->
          <div class="row row-primary" *ngIf="org != null">
            <div class="col-2" style="background-image:url(./assets/images/ChrispyKangaroo-bot.png);background-size:cover;min-height:80px"></div>
            <div class="col-10">
              <p class="lead mb-0">{{org}}</p>
              <span class="lead text-muted" style="font-size:1em !important;"><em>Priority Registration</em></span>
            </div>
          </div>

          <h3 class="h5 pt-4 pb-2">Register a new account</h3>

          <div *ngIf="!isLoading">
            <!-- Register Form -->
            <div *ngIf="registerResult?.status != 'CONFIRMED' && (email == null || email.trim().length == 0) && rsiCitizen == null">
              <div class="input-group form-group mb-2">
                <div class="input-group-prepend"><span class="input-group-text"><i class="fe-icon-mail"></i></span></div>
                <input class="form-control" type="email" placeholder="Email Address" [(ngModel)]="checkEmail" [ngModelOptions]="{standalone: true}">
                <div class="input-group-append">
                  <a type="button" class="btn btn-primary" href="./register?email={{checkEmail}}"><i class="fal fa-arrow-circle-right"></i></a>
                </div>
              </div>
              <p class="text-center my-3">- or -</p>
            </div>

            <form [formGroup]="registerForm" (ngSubmit)="registerSubmit()" *ngIf="registerResult?.status == null || registerResult?.status == 'UNKNOWN'">

              <!-- rsiHandle -->
              <app-rsi-handle (rsiHandleFound)="rsiHandleFound($event)" *ngIf="rsiCitizen?.handle == null"></app-rsi-handle>
              <div class="input-group mb-5" *ngIf="rsiCitizen?.handle != null">
                <div class="input-group-prepend" style="background-size:cover;background-image:url({{rsiCitizen.thumbnailPath}});width:3.3em;">
                </div>
                <input class="form-control" type="text" value="{{rsiCitizen.handle}}" disabled />
              </div>

              <!-- email -->
              <div *ngIf="rsiCitizen?.handle != null">
                <div class="input-group form-group mb-2">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="fe-icon-mail"></i></span></div>
                  <input class="form-control" formControlName="email" type="email" placeholder="Email Address">
                </div>
                <p class="text-muted text-sm mt-2 mb-4">
                  <em>You'll use your email address to login.</em>
                </p>

                <!-- password -->
                <div class="input-group form-group mb-2">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="far fa-key"></i></span></div>
                  <input class="form-control" formControlName="password" type="password" placeholder="Password">
                </div>
                <!-- validation -->
                <div *ngIf="registerForm.controls['password'].hasError('minlength')">
                  <span class="text-danger">Your password should be at least 8 chars long</span>
                </div>
                <div *ngIf="registerForm.controls['password'].hasError('hasNumber')">
                  <span class="text-danger">Your password should have a number</span>
                </div>
                <div *ngIf="registerForm.controls['password'].hasError('hasCapitalCase')">
                  <span class="text-danger">Your password should have a capital letter</span>
                </div>
                <div *ngIf="registerForm.controls['password'].hasError('hasSmallCase')">
                  <span class="text-danger">Your password should have a lower-case letter</span>
                </div>
                <div *ngIf="registerForm.controls['password'].hasError('hasSpecialCharacters')">
                  <span class="text-danger">Your password should have a special character</span>
                </div>

                <div class="input-group form-group mb-2">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="far fa-key"></i></span></div>
                  <input class="form-control" formControlName="confirmPassword" type="password" placeholder="Confirm Password">
                </div>
                <!-- validation -->
                <div *ngIf="registerForm.controls['confirmPassword'].hasError('mustMatch')">
                  <span class="text-danger">Passwords do not match</span>
                </div>

                <!-- terms -->
                <div class="input-group form-group form-check mb-2 mt-5">
                  <input type="checkbox" class="form-check-input" formControlName="acceptTerms">
                  <label class="form-check-label text-sm">I understand that SC TradeMasters is in early development. Data may be inaccurate and may be wiped at any time without notice</label>
                </div>
              </div>

              <button type="submit" class="btn btn-primary btn-block mt-5" [disabled]="registerForm.invalid || isRegistering">
                <span *ngIf="isRegistering"><i class="fas fa-cog fa-spin"></i> Registering...</span>
                <span *ngIf="!isRegistering">Register</span>
              </button>
            </form>

            <!-- register form -->
            <!-- Confirm Form -->
            <div *ngIf="registerResult?.status == 'UNCONFIRMED' && !confirmSuccess">
              <p>
                Please check your email for the steps to confirm your account, then click the confirm button below.
              </p>
              <button class="btn btn-primary btn-lg mt-2 btn-block mt-4 mb-2" (click)="confirmSubmit()"
                      [class.btn-primary]="!isConfirming && !confirmSuccess && !confirmError"
                      [class.btn-info]="isConfirming"
                      [class.btn-danger]="!isConfirming && confirmError">
                <span *ngIf="!isConfirming && !confirmSuccess && !confirmError">Confirm</span>
                <span *ngIf="!isConfirming && !confirmSuccess && confirmError">
                  <i class="far fa-times-circle"></i>
                  Unable to confirm your account
                </span>
                <span *ngIf="isConfirming"><i class="fas fa-cog fa-spin"></i> Confirming...</span>
              </button>
              <p class="text-sm text-muted" *ngIf="!isConfirming && confirmSuccess == false && confirmError == true">
                We were unable to confirm your account. Please check your email for the steps to confirm your account, then click the confirm button above. If we can help, you can always reach us on our Discord channel
              </p>
            </div>

            <!-- Register Complete -->
            <div class="card" *ngIf="confirmSuccess && registerResult?.discordHello != null">
              <div class="card-body">
                <h4 class="card-title">Welcome to SCTradeMasters!</h4>
                <p class="card-text">
                  You now have access to the tools and resources here on our website!
                </p>
                <p>
                  <strong><em>Ready to join our leaderboards?</em></strong> - Link your Discord Account now!
                </p>

                <div class="accordion" id="accordion1">

                  <!-- Card - SCTM Discord Server -->
                  <div class="card">
                    <div class="card-header">
                      <h6>
                        <a class="collapse" href="#collapseOne" data-toggle="collapse">
                          Option1: Use SCTradeMasters Discord server
                        </a>
                      </h6>
                    </div>
                    <div class="collapse" id="collapseOne" data-parent="#accordion1">
                      <div class="card-body">
                        <div>
                          <ul class="list-group">
                            <li class="list-group-item bg-secondary">
                              <h6>1. Join the SCTradeMasters Discord Server</h6>
                              <iframe src="https://discordapp.com/widget?id=475101141193981953&theme=dark" width="350" height="200" allowtransparency="true" frameborder="0"></iframe>
                            </li>
                            <li class="list-group-item bg-secondary">
                              2. Open up a Direct Message with ChrispyKoala using the command:<br />
                              <p class="user-select-all text-danger mt-3 p-3 bg-white" style="border:1px solid #bbb">
                                ~roo chat
                              </p>
                            </li>
                            <li class="list-group-item bg-secondary">
                              3. ChrispyKangaroo should have messaged you. In the chat with him, enter the command below:<br />
                              <p class="user-select-all text-danger mt-3 p-3 bg-white" style="border:1px solid #bbb">
                                ~roo hello {{fr?.email.value}} {{registerResult?.discordHello}}
                              </p>
                            </li>
                          </ul>


                        </div>
                      </div>
                    </div>

                    <!-- Card - Chat with the BOT -->
                    <div class="card">
                      <div class="card-header">
                        <h6>
                          <a href="#collapseTwo" data-toggle="collapse">
                            Option2: Use ChrispyKangaroo on your server
                          </a>
                        </h6>
                      </div>
                      <div class="collapse " id="collapseTwo" data-parent="#accordion1">
                        <div class="card-body">
                          <div>
                            <p class="text-muted text-sm">
                              We get it. You're a member of too many servers and there's no room in your heart for us.
                              If ChrispyKangaroo is running on your existing server, her's all you need to do:
                            </p>
                            <ul class="list-group">
                              <li class="list-group-item bg-secondary">
                                1. Find a channel on your server where ChrispyKangaroo is a member.<br />
                                <img src="./assets/images/ChrispyKangaroo-bot.png" alt="ChrispyKangaroo Bot" class="img-fluid mt-3" />
                              </li>
                              <li class="list-group-item bg-secondary">
                                2. Open up a Direct Message with ChrispyKoala using the command:<br />
                                <p class="user-select-all text-danger mt-3 p-3 bg-white" style="border:1px solid #bbb">
                                  ~roo chat
                                </p>
                              </li>
                              <li class="list-group-item bg-secondary">
                                3. ChrispyKangaroo should have messaged you. In the chat with him, enter the command below:<br />
                                <p class="user-select-all text-danger mt-3 p-3 bg-white" style="border:1px solid #bbb">
                                  ~roo hello {{fr?.email.value}} {{registerResult?.discordHello}}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <!-- Register but unknown discordId -->
            <div class="card" *ngIf="confirmSuccess && registerResult?.discordHello === null">
              <div class="card-body">
                <h4 class="card-title">Welcome to SCTradeMasters!</h4>
                <p class="card-text">Your account has been confirmed, and you should start seeing members areas opening up!</p>
                <p class="card-text">
                  If you are ready to join our leaderboards, the next step is to link your Discord Id.
                  <em>While we're developing, we're using Discord to upload your results to the leaderboard.</em>
                </p>
                <a class="btn btn-primary btn-block mt-2 " href="./members/discordlink">Login now and link your Discord Id</a>
              </div>
            </div>

            <!-- already registered -->
            <p *ngIf="!confirmSuccess && registerResult?.status == 'CONFIRMED'">This email address has already been registered. Please use the Login link below to access your account</p>
          </div>
        </div>
      </div>

      <br />
      <p class="text-center">
        <a href="./login">Login to your account</a>
      </p>
    </div>

  </div>
</div>
