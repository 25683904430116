import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LeaderboardsHomePageComponent } from './pages/leaderboards/leaderboards-home-page/leaderboards-home-page.component';
import { LeaderboardsMiningPageComponent } from './pages/leaderboards/leaderboards-mining-page/leaderboards-mining-page.component';
import { LeaderboardsTradePageComponent } from './pages/leaderboards/leaderboards-trade-page/leaderboards-trade-page.component';
import { LeaderboardsRacingPageComponent } from './pages/leaderboards/leaderboards-racing-page/leaderboards-racing-page.component';
import { LeaderboardsCombatHomePageComponent } from './pages/leaderboards/combat/leaderboards-combat-home-page/leaderboards-combat-home-page.component';
import { LeaderboardsCombatShipsPageComponent } from './pages/leaderboards/combat/leaderboards-combat-ships-page/leaderboards-combat-ships-page.component';
import { LeaderboardsCombatFPSPageComponent } from './pages/leaderboards/combat/leaderboards-combat-fpspage/leaderboards-combat-fpspage.component';
import { LeaderboardsCombatTOWPageComponent } from './pages/leaderboards/combat/leaderboards-combat-towpage/leaderboards-combat-towpage.component';
import { LoginPageComponent } from './pages/auth/login-page/login-page.component';
import { RegisterPageComponent } from './pages/auth/register-page/register-page.component';
import { DiscordLinkPageComponent } from './pages/auth/discord-link-page/discord-link-page.component';
import { AuthGuardService } from './_services/authGuards';

const routes: Routes = [
  { path: 'leaderboards/mining', component: LeaderboardsMiningPageComponent },
  { path: 'leaderboards/trade', component: LeaderboardsTradePageComponent },
  { path: 'leaderboards/racing', component: LeaderboardsRacingPageComponent },
  { path: 'leaderboards/combat', component: LeaderboardsCombatHomePageComponent },
  { path: 'leaderboards/combat/tow', component: LeaderboardsCombatTOWPageComponent },
  { path: 'leaderboards/combat/ship', component: LeaderboardsCombatShipsPageComponent },
  { path: 'leaderboards/combat/fps', component: LeaderboardsCombatFPSPageComponent },
  { path: 'leaderboards', component: LeaderboardsHomePageComponent },

  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: RegisterPageComponent },

  { path: 'members/discordlink', component: DiscordLinkPageComponent, canActivate: [AuthGuardService]  },


  { path: '**', component: HomePageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
