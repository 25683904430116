<div class="position-relative bg-cover bg-center bg-no-repeat px-4 py-5" style="background-image: url(./assets/thm/img/homepages/corporate-website/event-bg.jpg);">
  <span class="bg-overlay" style="opacity: .1;"></span>
  <div class="bg-content pt-4 text-center">
    <h3 class="font-family-body font-weight-light text-white mb-2"> Mining - Season 1</h3>
    <h2 class="text-white mb-2 pb-1">Ends 1 June, 2020</h2>
    <h5 class="font-family-body font-weight-light text-white mb-5">Hurry up to earn your spot!</h5>
    <div class="countdown countdown-style-2 countdown-light-skin h4 mb-3" data-date-time="6/1/2020 12:00" data-labels="{&quot;label-day&quot;: &quot;Days&quot;, &quot;label-hour&quot;: &quot;Hours&quot;, &quot;label-minute&quot;: &quot;Mins&quot;, &quot;label-second&quot;: &quot;Secs&quot;}"></div>
    <br>
    <a class="btn btn-style-4 btn-gradient mb-3" href="#">Join the season</a>
  </div>
</div>

<div class="container my-5">
  <div class="row">
    <div class="col-lg-6">
      <nav class="list-group">
        <a class="list-group-item d-flex justify-content-between align-items-center" href="#" *ngFor="let place of placements">
          Player Name
          <span class="badge badge-pill badge-secondary">{{place}}</span>
        </a>

      </nav>
    </div>
    <div class="col-lg-6">
      <nav class="list-group">
        <a class="list-group-item d-flex justify-content-between align-items-center" href="#" *ngFor="let place of placements">
          Org Name
          <span class="badge badge-pill badge-secondary">{{place}}</span>
        </a>

      </nav>
    </div>
  </div>
</div>
