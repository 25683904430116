import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leaderboards-mining-page',
  templateUrl: '../leaderboard-page.html',
  styleUrls: ['./leaderboards-mining-page.component.scss']
})
export class LeaderboardsMiningPageComponent implements OnInit {

  constructor() { }

  public placements: number[];

  ngOnInit(): void {
    this.placements = [];
    for (let i = 0; i < 101; i++) {
      this.placements.push((432 + (12 * i)));
    }
    this.placements = this.placements.sort((a, b) => b - a);
  }

}
