<footer class="bg-dark">

  <!-- Second Row-->
  <div class="pt-5" style="background-color: #30363d;">
    <div class="container">
      <div class="row">
        <!-- Contacts-->
        <div class="col-lg-3 col-sm-6">
          <div class="widget widget-contacts widget-light-skin mb-4">
            <h4 class="widget-title">Get in touch with us</h4>
            <ul>
              <li><i class="fab fa-discord"></i><span>Join us on</span><a href="https://discord.gg/Ejq5Pdp">Our Discord Server</a></li>
              <li><i class="fe-icon-mail"></i><span>Write us</span><a href="mailto:support@sctrademasters.com">support@sctrademasters.com</a></li>
            </ul>
          </div>
        </div>
        <!-- Mobile App-->
        <div class="col-lg-3 col-sm-6">
          <div class="widget widget-light-skin">
            <h4 class="widget-title">Our mobile app</h4>
            <a class="market-btn apple-btn market-btn-light-skin mr-3 mb-3" href="#"><span class="mb-subtitle">Coming soon to the</span><span class="mb-title">App Store</span></a>
            <a class="market-btn google-btn market-btn-light-skin mr-3 mb-3" href="#"><span class="mb-subtitle">Coming soon to the</span><span class="mb-title">Google Play</span></a>
          </div>
        </div>
        <!-- Subscription-->
        <div class="col-lg-6">
          <div class="widget widget-light-skin">
            <h4 class="widget-title">Be informed</h4>
            <form action="https://studio.us12.list-manage.com/subscribe/post?u=c7103e2c981361a6639545bd5&amp;amp;id=29ca296126" method="post" target="_blank" novalidate>
              <div class="input-group">
                <input class="form-control form-control-light-skin" type="email" name="EMAIL" placeholder="Email address">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">Sign Up</button>
                </div>
              </div><small class="form-text text-white opacity-50 pt-1">Subscribe to our Newsletter to receive early discount offers, latest news, sales and promo information.</small>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                <input type="text" name="b_c7103e2c981361a6639545bd5_1194bb7544" tabindex="-1">
              </div>
            </form>
            <div class="widget pt-3"><a class="social-btn sb-style-6 sb-facebook sb-light-skin" href="#"><i class="socicon-facebook"></i></a><a class="social-btn sb-style-6 sb-twitter sb-light-skin" href="#"><i class="socicon-twitter"></i></a><a class="social-btn sb-style-6 sb-youtube sb-light-skin" href="#"><i class="socicon-youtube"></i></a><a class="social-btn sb-style-6 sb-instagram sb-light-skin" href="#"><i class="socicon-instagram"></i></a><a class="social-btn sb-style-6 sb-pinterest sb-light-skin" href="#"><i class="socicon-pinterest"></i></a></div>
          </div>
        </div>
      </div>
      <hr class="hr-light">
      <div class="d-md-flex justify-content-between align-items-center py-4 text-center text-md-left">
        <div class="order-2">
          <a class="footer-link text-white" href="#">About</a>
          <a class="footer-link text-white ml-3" href="#">Help &amp; Info</a>
          <a class="footer-link text-white ml-3" href="#">Privacy Policy</a>
          <span class="ml-3 text-muted">v</span><span class="text-white">{{appVersion}}</span>
        </div>
        <p class="m-0 text-sm text-white order-1"><span class='opacity-60'>© All rights reserved. </span> <strong>SC</strong>TradeMasters</p>
      </div>
    </div>
  </div>
</footer>
<!-- Back To Top Button--><a class="scroll-to-top-btn" href="#"><i class="fe-icon-chevron-up"></i></a>
<!-- Backdrop-->
<div class="site-backdrop"></div>
