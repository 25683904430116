import { Component, OnInit, Input } from '@angular/core';
import { RSICitizen } from '../../_models/rsiCitizen';
import { SCTMApiService } from '../../_services/sctmApi.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() appVersion: string;

  isLoggedIn = false;
  isDiscordLinked = false;

  rsiCitizen: RSICitizen;

  constructor(private api: SCTMApiService) {
    api.rsiCitizen.subscribe(entry => this.rsiCitizenChanged(entry));
  }

  ngOnInit(): void {
    this.isLoggedIn = this.api.isLoggedIn();
    if (this.api.GetDiscordCode().startsWith('code:')) this.isDiscordLinked = false;
    else this.isDiscordLinked = true;
  }

  rsiCitizenChanged(entry: RSICitizen) {
    this.rsiCitizen = entry;
  }

}
