import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SCTMApiService } from './sctmApi.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private api: SCTMApiService, public router: Router) { }

  canActivate(): boolean {
    if (!this.api.isLoggedIn()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
