<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label for="reg-email">E-mail Address</label>
      <input class="form-control" type="email" required id="reg-email">
      <div class="invalid-feedback">Please enter valid email address!</div>
    </div>
  </div>
  <div class="col-12">
    <div class="form-group">
      <label for="reg-password">Password</label>
      <input class="form-control" type="password" required id="reg-password">
      <div class="invalid-feedback">Please enter password!</div>
    </div>
  </div>
  <div class="col-12">
    <div class="form-group">
      <label for="reg-password-confirm">Confirm Password</label>
      <input class="form-control" type="password" required id="reg-password-confirm">
      <div class="invalid-feedback">Passwords do not match!</div>
    </div>
  </div>
</div>
