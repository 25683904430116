import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { LeaderboardsHomePageComponent } from './pages/leaderboards/leaderboards-home-page/leaderboards-home-page.component';
import { LeaderboardsMiningPageComponent } from './pages/leaderboards/leaderboards-mining-page/leaderboards-mining-page.component';
import { LeaderboardsTradePageComponent } from './pages/leaderboards/leaderboards-trade-page/leaderboards-trade-page.component';
import { LeaderboardsRacingPageComponent } from './pages/leaderboards/leaderboards-racing-page/leaderboards-racing-page.component';
import { LeaderboardsCombatHomePageComponent } from './pages/leaderboards/combat/leaderboards-combat-home-page/leaderboards-combat-home-page.component';
import { LeaderboardsCombatFPSPageComponent } from './pages/leaderboards/combat/leaderboards-combat-fpspage/leaderboards-combat-fpspage.component';
import { LeaderboardsCombatTOWPageComponent } from './pages/leaderboards/combat/leaderboards-combat-towpage/leaderboards-combat-towpage.component';
import { LeaderboardsCombatShipsPageComponent } from './pages/leaderboards/combat/leaderboards-combat-ships-page/leaderboards-combat-ships-page.component';
import { LoginPageComponent } from './pages/auth/login-page/login-page.component';
import { SupportersComponent } from './components/supporters/supporters.component';
import { RsiHandleComponent } from './components/register/rsi-handle/rsi-handle.component';
import { AccountDetailsComponent } from './components/register/account-details/account-details.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ConfigService } from './_services/config.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, ObservableInput, of } from 'rxjs';
import { SCTMApiService } from './_services/sctmApi.service';
import { ToastrModule } from 'ngx-toastr';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { RegisterPageComponent } from './pages/auth/register-page/register-page.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DiscordLinkPageComponent } from './pages/auth/discord-link-page/discord-link-page.component';
import { AuthGuardService } from './_services/authGuards';

export function load(http: HttpClient, config: ConfigService): (() => Promise<boolean>) {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      http.get('./assets/config.json')
        .pipe(
          map((x: ConfigService) => {
            config.apiUrl = x.apiUrl;
            resolve(true);
          }),
          catchError((x: { status: number }, caught: Observable<void>): ObservableInput<{}> => {
            if (x.status !== 404) {
              resolve(false);
            }
            config.apiUrl = 'https://localhost:44381/';
            resolve(true);
            return of({});
          })
        ).subscribe();
    });
  };
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomePageComponent,
    FooterComponent,
    LeaderboardsHomePageComponent,
    LeaderboardsMiningPageComponent,
    LeaderboardsTradePageComponent,
    LeaderboardsRacingPageComponent,
    LeaderboardsCombatHomePageComponent,
    LeaderboardsCombatFPSPageComponent,
    LeaderboardsCombatTOWPageComponent,
    LeaderboardsCombatShipsPageComponent,
    LoginPageComponent,
    SupportersComponent,
    RsiHandleComponent,
    AccountDetailsComponent,
    RegisterPageComponent,
    DiscordLinkPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    CarouselModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: load,
      deps: [
        HttpClient,
        ConfigService
      ],
      multi: true
    },
    ConfigService,
    SCTMApiService,
    authInterceptorProviders,
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
