<!-- Off-Canvas Menu-->
<div class="offcanvas-container is-triggered offcanvas-container-reverse" id="mobile-menu">
  <span class="offcanvas-close"><i class="fe-icon-x"></i></span>
  <div class="px-4 pb-4">
    <h6>Menu</h6>
    <div class="d-flex justify-content-between pt-2">
      <a class="btn btn-primary btn-sm btn-block" href="./login"><i class="fe-icon-user"></i>Login</a>
    </div>
  </div>
  <div class="offcanvas-scrollable-area border-top" style="height:calc(100% - 235px); top: 144px;">
    <!-- Mobile Menu-->
    <div class="accordion mobile-menu" id="accordion-menu">
      <!-- Home-->
      <div class="card">
        <div class="card-header"><a class="mobile-menu-link active" href="/"><i class="fas fa-home mr-2"></i>Home</a></div>
      </div>

      <!-- Leaderboards-->
      <div class="card">
        <div class="card-header">
          <a class="mobile-menu-link" href="./leaderboards">
            <i class="fas fa-medal mr-2"></i>Leaderboards
          </a><a class="collapsed" href="#leaderboards-submenu" data-toggle="collapse"></a>
        </div>
        <div class="collapse" id="leaderboards-submenu" data-parent="#accordion-menu">
          <div class="card-body">
            <ul>
              <li class="dropdown-item">
                <a href="./leaderboards/mining">
                  <span class="text-warning"><i class="fal fa-shovel mr-2"></i>Mining</span>
                </a>
                <ul class="mobile-sublevel">
                  <li class="dropdown-item"><a href="./leaderboards/mining/current">Current Season</a></li>
                  <li class="dropdown-item"><a href="./leaderboards/mining/ended">Previous Seasons</a></li>
                </ul>
              </li>
              <li class="dropdown-item">
                <a href="./leaderboards/trade">
                  <span class="text-info"><i class="fal fa-chart-line mr-2"></i>Trade</span>
                </a>
                <ul class="mobile-sublevel">
                  <li class="dropdown-item"><a href="./leaderboards/trade/current">Current Season</a></li>
                  <li class="dropdown-item"><a href="./leaderboards/ended">Previous Seasons</a></li>
                </ul>
              </li>
              <li class="dropdown-item">
                <a href="./leaderboards/racing">
                  <span class="text-success"><i class="fal fa-flag-checkered mr-2"></i>Racing</span>
                </a>
                <ul class="mobile-sublevel">
                  <li class="dropdown-item"><a href="./leaderboards/racing/current">Current Season</a></li>
                  <li class="dropdown-item"><a href="./leaderboards/ended">Previous Seasons</a></li>
                </ul>
              </li>
              <li class="dropdown-item">
                <a href="./leaderboards/combat/tow">
                  <span class="text-danger"><i class="fal fa-bomb mr-2"></i>Theaters of War</span>
                </a>
                <ul class="mobile-sublevel">
                  <li class="dropdown-item"><a href="./leaderboards/combat/tow/current">Current Season</a></li>
                  <li class="dropdown-item"><a href="./leaderboards/combat/tow/ended">Previous Seasons</a></li>
                </ul>
              </li>
              <li class="dropdown-item">
                <a href="./leaderboards/combat/ship">
                  <span class="text-danger"><i class="fal fa-rocket mr-2"></i>Ship Combat</span>
                </a>
                <ul class="mobile-sublevel">
                  <li class="dropdown-item"><a href="./leaderboards/combat/ship/current">Current Season</a></li>
                  <li class="dropdown-item"><a href="./leaderboards/combat/ship/ended">Previous Seasons</a></li>
                </ul>
              </li>
              <li class="dropdown-item">
                <a href="./leaderboards/combat/fps">
                  <span class="text-danger"><i class="fal fa-raygun mr-2"></i>FPS Combat</span>
                </a>
                <ul class="mobile-sublevel">
                  <li class="dropdown-item"><a href="./leaderboards/combat/fps/current">Current Season</a></li>
                  <li class="dropdown-item"><a href="./leaderboards/combat/fps/ended">Previous Seasons</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Tools -->
      <div class="card">
        <div class="card-header">
          <a class="mobile-menu-link" href="blog-grid-rs.html">
            <i class="fas fa-tools mr-2"></i>Solutions &amp; Tools
          </a><a class="collapsed" href="#tools-submenu" data-toggle="collapse"></a>
        </div>
        <div class="collapse" id="tools-submenu" data-parent="#accordion-menu">
          <div class="card-body">
            <ul>
              <li class="dropdown-header">Solutions</li>
              <li class="dropdown-item"><a href="#">Discord Bot</a></li>
              <li class="dropdown-item"><a href="#">Windows Client</a></li>
              <li class="dropdown-item"><a href="#">Mobile App</a></li>
              <li class="dropdown-header">Tools</li>
              <li class="dropdown-item"><a href="#">Mining Tools</a></li>
              <li class="dropdown-item"><a href="#">Economy Tools</a></li>
              <li class="dropdown-item"><a href="#">APIs</a></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Blog-->
      <div class="card">
        <div class="card-header">
          <a class="mobile-menu-link" href="blog-grid-rs.html">
            <i class="fas fa-pencil-alt mr-2"></i>Blog
          </a><a class="collapsed" href="#blog-submenu" data-toggle="collapse"></a>
        </div>
        <div class="collapse" id="blog-submenu" data-parent="#accordion-menu">
          <div class="card-body">
            <ul>
              <li class="dropdown-header">Latest Posts</li>
              <li class="dropdown-item"><a href="blog-grid-rs.html">Grid Right Sidebar</a></li>
              <li class="dropdown-item"><a href="blog-grid-ls.html">Grid Left Sidebar</a></li>
              <li class="dropdown-item"><a href="blog-grid-ns.html">Grid No Sidebar</a></li>
              <li class="dropdown-header">Popular Posts</li>
              <li class="dropdown-item"><a href="blog-single-gallery.html">Post Gallery</a></li>
              <li class="dropdown-item"><a href="blog-single-carousel.html">Post Image Carousel</a></li>
              <li class="dropdown-item"><a href="blog-single-video.html">Post Video</a></li>
              <li class="dropdown-item"><a href="blog-single-audio.html">Post Audio</a></li>
              <li class="dropdown-item"><a href="blog-single-quotation.html">Post Quotation</a></li>
              <li class="dropdown-item"><a href="blog-single-link.html">Post Link</a></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Account-->
      <div class="card">
        <div class="card-header">
          <a class="mobile-menu-link" href="#">
            <i class="fas fa-user mr-2"></i>Account
          </a><a class="collapsed" href="#account-submenu" data-toggle="collapse"></a>
        </div>
        <div class="collapse" id="account-submenu" data-parent="#accordion-menu">
          <div class="card-body">
            <ul>
              <li class="dropdown-item" *ngIf="!isLoggedIn"><a href="./login">Login</a></li>
              <li class="dropdown-item" *ngIf="isLoggedIn && !isDiscordLinked"><a href="./members/discordlink">Link Discord</a></li>
              <li class="dropdown-item" *ngIf="isLoggedIn"><a href="#">Profile</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-footer px-4 pt-3 pb-2 text-center">
    <a class="social-btn sb-style-3 sb-twitter" href="#"><i class="socicon-twitter"></i></a>
    <a class="social-btn sb-style-3 sb-facebook" href="#"><i class="socicon-facebook"></i></a>
    <a class="social-btn sb-style-3 sb-pinterest" href="#"><i class="socicon-pinterest"></i></a>
    <a class="social-btn sb-style-3 sb-instagram" href="#"><i class="socicon-instagram"></i></a>
  </div>
</div>

<!-- Navbar: Default-->
<!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
<header class="navbar-wrapper navbar-sticky">
  <div class="d-table-cell align-middle pr-md-3">
    <a class="navbar-brand mr-1" href="index.html">
      <span><strong class="text-primary">SC</strong>TradeMasters</span>
    </a>
  </div>
  <div class="d-table-cell w-100 align-middle pl-md-3">
    <div class="navbar-top d-none d-lg-flex justify-content-between align-items-center">
      <div>
        <a class="navbar-link mr-3" href="mailto:support@sctrademasters.com"><i class="fe-icon-mail"></i>support@sctrademasters.com</a>
        <a class="social-btn sb-style-3 sb-twitter" href="#"><i class="socicon-twitter"></i></a>
        <a class="social-btn sb-style-3 sb-facebook" href="#"><i class="socicon-facebook"></i></a>
        <a class="social-btn sb-style-3 sb-pinterest" href="#"><i class="socicon-pinterest"></i></a>
        <a class="social-btn sb-style-3 sb-instagram" href="#"><i class="socicon-instagram"></i></a>
      </div>
      <div>
        <ul class="list-inline mb-0" *ngIf="rsiCitizen?.handle == null">
          <li class="dropdown-toggle mr-2">
            <a class="navbar-link" href="account-login.html"><i class="fe-icon-user"></i>Login or Create account</a>
            <div class="dropdown-menu right-aligned p-3 text-center" style="min-width: 200px;">
              <p class="text-sm opacity-70">Sign in to your account or register to join our leaderboards, experience systems, and other coming features</p>
              <a class="btn btn-primary btn-sm btn-block" href="./login">Sign In</a>
              <p class="text-sm text-muted mt-3 mb-0">New Member? <a href='./register'>Register</a></p>
            </div>
          </li>
        </ul>

        <ul class="list-inline mb-0" *ngIf="rsiCitizen?.handle != null">
          <li class="dropdown-toggle mr-2">
            <a class="navbar-link" href="account-login.html">
              <img src="{{rsiCitizen.thumbnailPath}}" alt="{{rsiCitizen.handle}}" style="height:18px;width:auto;" />
              {{rsiCitizen.handle}}
            </a>
            <div class="dropdown-menu right-aligned p-3 text-center" style="min-width: 200px;">
              <p class="text-sm opacity-70">Welcome {{rsiCitizen.handle}}, We're still working on our account features. They will be online shortly!</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="navbar justify-content-end justify-content-lg-between">
      <!-- Search-->
      <form class="search-box" method="get">
        <input type="text" id="site-search" placeholder="Type A or C to see suggestions"><span class="search-close"><i class="fe-icon-x"></i></span>
      </form>
      <!-- Main Menu-->
      <ul class="navbar-nav d-none d-lg-block">
        <!-- Home-->
        <li class="nav-item active"><a href="/"><i class="fas fa-home"></i></a></li>

        <!-- Leaderboards -->
        <li class="nav-item mega-dropdown-toggle active">
          <a class="nav-link" href="./leaderboards">Leaderboards</a>
          <div class="dropdown-menu mega-dropdown">
            <div class="d-flex">
              <div class="column">
                <div class="widget widget-custom-menu">
                  <ul>
                    <li class="dropdown-header font-weight-medium text-warning border-warning text-uppercase pl-0">
                      <i class="fal fa-shovel mr-2"></i>Mining
                    </li>
                    <li><a href="./leaderboards/mining">Mining Home</a></li>
                    <li><a href="./leaderboards/mining/current">Current Season<span class="badge badge-primary"><span class="text-white">Ends 1Jun</span></span></a></li>
                    <li><a href="./leaderboards/mining/previous">Season 1<span class="badge badge-secondary"><span class="text-black">Ended 1May</span></span></a></li>
                    <li><a href="./leaderboards/mining/ended">Previous Seasons</a></li>
                  </ul>
                </div>

                <div class="widget widget-custom-menu mt-5">
                  <ul>
                    <li class="dropdown-header font-weight-medium text-danger border-danger text-uppercase pl-0">
                      <i class="fal fa-bomb mr-2"></i>Theater of War
                    </li>
                    <li><a href="./leaderboards/combat/tow">Theater of War Home</a></li>
                    <li><a href="./leaderboards/combat/tow/current">Current Season<span class="badge badge-primary"><span class="text-white">Ends 1Jun</span></span></a></li>
                    <li><a href="./leaderboards/combat/tow/previous">Season 1<span class="badge badge-secondary"><span class="text-black">Ended 1May</span></span></a></li>
                    <li><a href="./leaderboards/combat/tow/ended">Previous Seasons</a></li>
                  </ul>
                </div>
              </div>
              <div class="column">
                <div class="widget widget-custom-menu">
                  <ul>
                    <li class="dropdown-header font-weight-medium text-info border-info text-uppercase pl-0">
                      <i class="fal fa-chart-line mr-2"></i>Trade
                    </li>
                    <li><a href="./leaderboards/trade">Trade Home</a></li>
                    <li><a href="./leaderboards/trade/current">Current Season<span class="badge badge-primary"><span class="text-white">Ends 1Jun</span></span></a></li>
                    <li><a href="./leaderboards/trade/previous">Season 1<span class="badge badge-secondary"><span class="text-black">Ended 1May</span></span></a></li>
                    <li><a href="./leaderboards/trade/ended">Previous Seasons</a></li>
                  </ul>
                </div>

                <div class="widget widget-custom-menu mt-5">
                  <ul>
                    <li class="dropdown-header font-weight-medium text-danger border-danger text-uppercase pl-0">
                      <i class="fal fa-rocket mr-2"></i>Ship Combat
                    </li>
                    <li><a href="./leaderboards/combat/ship">Ship Home</a></li>
                    <li><a href="./leaderboards/combat/ship/current">Current Season<span class="badge badge-primary"><span class="text-white">Ends 1Jun</span></span></a></li>
                    <li><a href="./leaderboards/combat/ship/previous">Season 1<span class="badge badge-secondary"><span class="text-black">Ended 1May</span></span></a></li>
                    <li><a href="./leaderboards/combat/ship/ended">Previous Seasons</a></li>
                  </ul>
                </div>
              </div>
              <div class="column">
                <div class="widget widget-custom-menu">
                  <ul>
                    <li class="dropdown-header font-weight-medium text-success border-success text-uppercase pl-0">
                      <i class="fal fa-flag-checkered mr-2"></i>Racing
                    </li>
                    <li><a href="./leaderboards/racing">Racing Home</a></li>
                    <li><a href="./leaderboards/racing/current">Current Season<span class="badge badge-primary"><span class="text-white">Ends 1Jun</span></span></a></li>
                    <li><a href="./leaderboards/racing/previous">Season 1<span class="badge badge-secondary"><span class="text-black">Ended 1May</span></span></a></li>
                    <li><a href="./leaderboards/racing/ended">Previous Seasons</a></li>
                  </ul>
                </div>

                <div class="widget widget-custom-menu mt-5">
                  <ul>
                    <li class="dropdown-header font-weight-medium text-danger border-danger text-uppercase pl-0">
                      <i class="fal fa-raygun mr-2"></i>FPS Combat
                    </li>
                    <li><a href="./leaderboards/combat/fps">FPS Home</a></li>
                    <li><a href="./leaderboards/combat/fps/current">Current Season<span class="badge badge-primary"><span class="text-white">Ends 1Jun</span></span></a></li>
                    <li><a href="./leaderboards/combat/fps/previous">Season 1<span class="badge badge-secondary"><span class="text-black">Ended 1May</span></span></a></li>
                    <li><a href="./leaderboards/combat/fps/ended">Previous Seasons</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>

        <!-- Tools & Solutions -->
        <li class="nav-item mega-dropdown-toggle">
          <a class="nav-link" href="blog-grid-rs.html">Solutions &amp; Tools</a>
          <div class="dropdown-menu mega-dropdown">
            <div class="d-flex">
              <div class="column">
                <div class="widget widget-custom-menu">
                  <h4 class="widget-title mb-1">Solutions</h4>
                  <p class="text-muted text-sm mb-3">Our solutions are here to help you interface with SCTradeMasters systems.</p>
                  <ul>
                    <li><a href="#"><i class="fab fa-discord mr-2"></i>Discord Bot</a></li>
                    <li><a href="#"><i class="fab fa-windows mr-2"></i>Windows Client</a></li>
                    <li><a href="#"><i class="fab fa-apple mr-2"></i>Mobile App</a></li>
                  </ul>
                </div>
              </div>
              <div class="column">
                <div class="widget widget-custom-menu">
                  <h4 class="widget-title mb-1">Tools</h4>
                  <p class="text-muted text-sm mb-3">Our tools are here to help you build your own apps and websites.</p>
                  <ul>
                    <li><a href="#">Mining Tools</a></li>
                    <li><a href="#">Economy Tools</a></li>
                    <li><a href="#">APIs</a></li>
                  </ul>
                </div>
              </div>
              <div class="column" style="width: 280px;">
                <div class="widget widget-featured-posts">
                  <h4 class="widget-title">Getting Started</h4><a class="featured-post" href="blog-single-gallery.html">
                    <div class="featured-post-thumb">
                      <img src="https://via.placeholder.com/120x120" alt="Post Thumbnail" />
                    </div>
                    <div class="featured-post-info">
                      <div class="featured-post-meta"><span class="text-primary opacity-70"><i class="fab fa-discord mr-2"></i>Discord Bot</span></div>
                      <div class="featured-post-title">Adding the Discord Bot to your server</div>
                    </div>
                  </a><a class="featured-post" href="blog-single-carousel.html">
                    <div class="featured-post-thumb">
                      <img src="https://via.placeholder.com/120x120" alt="Post Thumbnail" />
                    </div>
                    <div class="featured-post-info">
                      <div class="featured-post-meta"><span class="text-primary opacity-70"><i class="fab fa-windows mr-2"></i>Windows Client</span></div>
                      <div class="featured-post-title">Installing and using the Windows client</div>
                    </div>
                  </a><a class="featured-post" href="blog-single-video.html">
                    <div class="featured-post-thumb">
                      <img src="https://via.placeholder.com/120x120" alt="Post Thumbnail" />
                    </div>
                    <div class="featured-post-info">
                      <div class="featured-post-meta"><span class="text-primary opacity-70"><i class="far fa-chart-network mr-2"></i>RestAPI</span></div>
                      <div class="featured-post-title">Using our RestAPIs</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>

        <!-- Blog-->
        <li class="nav-item mega-dropdown-toggle">
          <a class="nav-link" href="blog-grid-rs.html">Blog</a>
          <div class="dropdown-menu mega-dropdown">
            <div class="d-flex">
              <div class="column">
                <div class="widget widget-custom-menu">
                  <h4 class="widget-title">Latest Articles</h4>
                  <ul>
                    <li><a href="blog-grid-rs.html">Grid Right Sidebara</a></li>
                    <li><a href="blog-grid-ls.html">Grid Left Sidebar</a></li>
                    <li><a href="blog-grid-ns.html">Grid No Sidebar</a></li>
                  </ul>
                </div>
              </div>
              <div class="column">
                <div class="widget widget-custom-menu">
                  <h4 class="widget-title">Popular Articles</h4>
                  <ul>
                    <li><a href="blog-single-gallery.html">Post Gallery</a></li>
                    <li><a href="blog-single-carousel.html">Post Image Carousel</a></li>
                    <li><a href="blog-single-video.html">Post Video</a></li>
                    <li><a href="blog-single-audio.html">Post Audio</a></li>
                    <li><a href="blog-single-quotation.html">Post Quotation</a></li>
                    <li><a href="blog-single-link.html">Post Link</a></li>
                  </ul>
                </div>
              </div>
              <div class="column" style="width: 280px;">
                <div class="widget widget-featured-posts">
                  <h4 class="widget-title">Meet our Authors</h4>
                  <a class="featured-post" href="blog-single-carousel.html">
                    <div class="featured-post-thumb">
                      <img src="https://via.placeholder.com/120x120" alt="Post Thumbnail" />
                    </div>
                    <div class="featured-post-info">
                      <div class="featured-post-meta"><span class="text-primary opacity-70"><i class="fas fa-user-tie mr-2"></i>ChrispyKoala</span><span class="ml-3"><i class="fal fa-newspaper mr-2"></i>17</span></div>
                      <div class="featured-post-title">Cherry 5 WordPress Framework Sneaky Peaks.</div>
                    </div>
                  </a>
                  <a class="featured-post" href="blog-single-carousel.html">
                    <div class="featured-post-thumb">
                      <img src="https://via.placeholder.com/120x120" alt="Post Thumbnail" />
                    </div>
                    <div class="featured-post-info">
                      <div class="featured-post-meta"><span class="text-primary opacity-70"><i class="fas fa-user-tie mr-2"></i>ChrispyKoala</span><span class="ml-3"><i class="fal fa-newspaper mr-2"></i>17</span></div>
                      <div class="featured-post-title">Cherry 5 WordPress Framework Sneaky Peaks.</div>
                    </div>
                  </a>
                  <a class="featured-post" href="blog-single-carousel.html">
                    <div class="featured-post-thumb">
                      <img src="https://via.placeholder.com/120x120" alt="Post Thumbnail" />
                    </div>
                    <div class="featured-post-info">
                      <div class="featured-post-meta"><span class="text-primary opacity-70"><i class="fas fa-user-tie mr-2"></i>ChrispyKoala</span><span class="ml-3"><i class="fal fa-newspaper mr-2"></i>17</span></div>
                      <div class="featured-post-title">Cherry 5 WordPress Framework Sneaky Peaks.</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>

        <!-- Account-->
        <li class="nav-item dropdown-toggle">
          <a class="nav-link" href="#">Account</a>
          <ul class="dropdown-menu">
            <li class="dropdown-item" *ngIf="!isLoggedIn"><a href="./login">Login</a></li>
            <li class="dropdown-item" *ngIf="isLoggedIn && !isDiscordLinked"><a href="./members/discordlink">Link Discord</a></li>
            <li class="dropdown-item" *ngIf="isLoggedIn"><a href="#">Profile</a></li>
          </ul>
        </li>
      </ul>
      <div>
        <ul class="navbar-buttons d-inline-block align-middle mr-lg-2">
          <li class="d-block d-lg-none"><a href="#mobile-menu" data-toggle="offcanvas"><i class="fe-icon-menu"></i></a></li>
          <li><a href="#" data-toggle="search"><i class="fe-icon-search"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</header>
