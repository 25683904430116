import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leaderboards-combat-ships-page',
  templateUrl: './leaderboards-combat-ships-page.component.html',
  styleUrls: ['./leaderboards-combat-ships-page.component.scss']
})
export class LeaderboardsCombatShipsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
