import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SCTMApiService } from '../../../_services/sctmApi.service';
import { TokenStorageService } from '../../../_services/token-storage.service';
import { LoginResult } from '../../../_models/loginResult';
import { ActivatedRoute, Router } from '@angular/router';
import { RSICitizen } from '../../../_models/rsiCitizen';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  @Output() rsiCitizen: EventEmitter<RSICitizen> = new EventEmitter();

  isLogin = false;
  loginForm: FormGroup = new FormGroup({});
  loginError = false;

  constructor(private formBuilder: FormBuilder, private api: SCTMApiService, private tokenStorage: TokenStorageService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
    
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  onLogin() {
    this.loginError = false;
    this.isLogin = true;
    this.api.Login_POST(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value, null, null).toPromise().then((response: LoginResult) => {
      console.log("login completed -------");
      console.log(response)

      this.tokenStorage.saveToken(response.jwt);

      this.api.rsiCitizen_GET(localStorage.getItem('auth-rsiHandle'), null, null).toPromise().then(respCitizen => {
        console.log(respCitizen);
        console.log("emitting RSICitizen: " + respCitizen.handle);
        this.rsiCitizen.emit(respCitizen);
      });

      this.router.navigate(['./members/discordlink']);

    }, error => {
      this.loginError = true;
    }).finally(() => { this.isLogin = false; });
  }
}
