<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label for="reg-fn" class="mr-3">Enter your RSI Handle</label>
      <div class="input-group">
        <div class="input-group-prepend" style="
          background-size:cover;
          background-image:url({{rsiCitizenResult?.thumbnailPath}});
          width:3.3em;">
          <span class="input-group-text" *ngIf="rsiCitizenResult?.thumbnailPath == null"><i class="fas fa-user"></i></span>
        </div>
        <input class="form-control" type="text" required id="reg-fn" [(ngModel)]="rsiHandleInput" [ngModelOptions]="{standalone: true}">
        <div class="input-group-append">
          <button type="button" class="btn btn-primary" (click)="getRSIProfile()" [disabled]="isLoading">
            <span *ngIf="!isLoading"><i class="fas fa-search"></i></span>
            <span *ngIf="isLoading"><i class="fas fa-cog fa-spin"></i></span>
          </button>
        </div>
      </div>
      <div class="invalid-feedback">Please enter your RSI Handle</div>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-12 col-lg-10">
    <p class="text-sm text-muted mb-1">
      Click <a href="https://robertsspaceindustries.com/account/settings" target="_blank">here</a>
      to get your RSI Handle.
    </p>
    <p class="text-sm text-muted">
      Don't have an RSI account yet? Signup is FREE, and if you use
      <a href="https://robertsspaceindustries.com/enlist?referral=STAR-S9LG-7GY9" target="_blank">this link</a>,
      you'll receive a <strong>bonus 5,000 credits!</strong>
    </p>
  </div>

</div>
