import { Component, OnInit } from '@angular/core';
import { SCTMApiService } from '../../../_services/sctmApi.service';

@Component({
  selector: 'app-discord-link-page',
  templateUrl: './discord-link-page.component.html',
  styleUrls: ['./discord-link-page.component.scss']
})
export class DiscordLinkPageComponent implements OnInit {

  discordCode: string;
  email: string;

  constructor(private api: SCTMApiService) { }

  ngOnInit(): void {
    const _dCode = this.api.GetDiscordCode();
    console.log(_dCode);
    if (_dCode.startsWith("code:")) {
      this.discordCode = _dCode.split(':')[1];
    }


    this.email = this.api.GetEmail();
  }

}
