<!-- Hero-->
<section class="bg-center bg-repeat-y box-shadow" style="background-color:#f5f5f5;background-image: url(./assets/thm/img/hero-bg.png);">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 order-md-2 overflow-hidden">
        <div><img class="d-block mx-auto img-fluid" src="./assets/images/chrispykangaroo.jpg" alt="ChrispyKangaroo" data-parallax="{&quot;scale&quot; : 1.15}"></div>
      </div>
      <div class="col-md-6 bg-white order-md-1 overflow-hidden">
        <div class="text-center text-md-left">
          <h2 class="h3 text-body pt-md-5 pb-3"><span class="d-block font-family-body font-weight-light pb-2">Hello!</span><span class="d-block font-family-body font-weight-light">I am <span class='font-weight-bold'>ChrispyKangaroo</span></span></h2>
          <h1 class="mb-4 mb-md-5" data-parallax="{&quot;x&quot; : 50}" style="color: #ac32e4;">
            Bot. Website. Community
          </h1>
          <div class="list-group">

            <!-- Bot -->
            <a class="list-group-item flex-column align-items-start active" href="#">
              <div class="d-flex flex-wrap w-100 justify-content-between">
                <h6>I'm a Bot</h6>
                <small class="d-block text-muted text-sm mb-2">
                  <i class="fab fa-discord"></i>
                </small>
              </div>
              <p class="text-md text-body">
                I add pep to your Star Citizen Organization's Discord channel. Things like leaderboards, Experience, and internal currency...
              </p>
              <small class="text-muted text-sm">Click here to learn more about the <strong><i class="fab fa-discord ml-2 mr-1"></i>Discord Bot</strong></small>
            </a>

            <!-- Website -->
            <a class="list-group-item flex-column align-items-start" href="#">
              <div class="d-flex flex-wrap w-100 justify-content-between">
                <h6>I'm a website</h6>
                <small class="d-block text-muted text-sm mb-2">
                  <i class="fal fa-globe"></i>
                </small>
              </div>
              <p class="text-md text-body">
                Not on Discord? You can access all our features here, or by usig our library of RestAPIs to your own website...
              </p>
              <small class="text-muted text-sm">Click here to learn more about our <strong><i class="fal fa-globe ml-2 mr-1"></i>Web / respAPI solutions</strong></small>
            </a>

            <!-- Community -->
            <a class="list-group-item flex-column align-items-start" href="#">
              <div class="d-flex flex-wrap w-100 justify-content-between">
                <h6>I'm a community</h6>
                <small class="d-block text-muted text-sm mb-2"><i class="fal fa-comments-alt"></i></small>
              </div>
              <p class="text-md text-body">
                From help with getting started with professions in Star Citizens, to tips on shaving seconds off your laps... We're bringing together
                experts in the various game mechanics to help you get the most out of Star Citizen.
              </p>
              <small class="text-muted text-sm">Click here to learn more about our <strong><i class="fal fa-comments-alt ml-2 mr-1"></i>Community</strong></small>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<!-- Editor's Picks-->
<section class="container py-5 mt-5">
  <h2 class="h4 block-title text-center mt-2">Get to know <strong>SC</strong>TradeMasters</h2>
  <div class="owl-carousel carousel-flush pt-3" data-owl-carousel="{ &quot;nav&quot;: false, &quot;dots&quot;: true, &quot;autoHeight&quot;: true, &quot;responsive&quot;: {&quot;0&quot;:{&quot;items&quot;:1},&quot;630&quot;:{&quot;items&quot;:2},&quot;991&quot;:{&quot;items&quot;:3},&quot;1200&quot;:{&quot;items&quot;:3}} }">
    <div class="mb-30 pb-4">
      <div class="card blog-card">
        <div class="card-body">
          <h5 class="post-title"><a href="blog-single-carousel.html">My First Post About Technology. This Is How...</a></h5>
          <p class="text-muted">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p><a class="tag-link" href="#">Business</a><a class="tag-link" href="#">Technology</a>
        </div>
        <div class="card-footer">
          <a class="post-author" href="blog-single-carousel.html">
            <div class="post-author-avatar">
              <img src="https://via.placeholder.com/56x56" alt="Post Author" />
            </div>
            <div class="post-author-name">Emanuel Ortega</div>
          </a>
          <div class="post-meta"><a href="blog-single-carousel.html#comments"><i class="fe-icon-message-square"></i>21</a><span><i class="fe-icon-clock"></i>Sep 03, 2018</span></div>
        </div>
      </div>
    </div>
    <div class="mb-30 pb-4">
      <div class="card blog-card">
        <div class="card-body">
          <h5 class="post-title"><a href="blog-single-audio.html">Deep Purple "Smoke on the Water". Song History.</a></h5>
          <p class="text-muted">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p><a class="tag-link" href="#">Music</a><a class="tag-link" href="#">Classic Rock</a>
        </div>
        <div class="card-footer">
          <a class="post-author" href="blog-single-audio.html">
            <div class="post-author-avatar">
              <img src="https://via.placeholder.com/56x56" alt="Post Author" />
            </div>
            <div class="post-author-name">Silvia Clark</div>
          </a>
          <div class="post-meta"><a href="blog-single-audio.html#comments"><i class="fe-icon-message-square"></i>3</a><span><i class="fe-icon-clock"></i>Jul 15, 2018</span></div>
        </div>
      </div>
    </div>
    <div class="mb-30 pb-4">
      <div class="card blog-card">
        <div class="card-body">
          <h5 class="post-title"><a href="blog-single-video.html">The List of Most Anticipated Movies of 2018.</a></h5>
          <p class="text-muted">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p><a class="tag-link" href="#">Movies</a><a class="tag-link" href="#">Trailers</a>
        </div>
        <div class="card-footer">
          <a class="post-author" href="blog-single-video.html">
            <div class="post-author-avatar">
              <img src="https://via.placeholder.com/56x56" alt="Post Author" />
            </div>
            <div class="post-author-name">Sara Palson</div>
          </a>
          <div class="post-meta"><a href="blog-single-video.html#comments"><i class="fe-icon-message-square"></i>31</a><span><i class="fe-icon-clock"></i>Jul 29, 2018</span></div>
        </div>
      </div>
    </div>
    <div class="mb-30 pb-4">
      <div class="card blog-card">
        <div class="card-body">
          <h5 class="post-title"><a href="blog-single-carousel.html">My Second Post About Technology. This Is Why...</a></h5>
          <p class="text-muted">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p><a class="tag-link" href="#">Business</a><a class="tag-link" href="#">Technology</a>
        </div>
        <div class="card-footer">
          <a class="post-author" href="blog-single-carousel.html">
            <div class="post-author-avatar">
              <img src="https://via.placeholder.com/56x56" alt="Post Author" />
            </div>
            <div class="post-author-name">Emanuel Ortega</div>
          </a>
          <div class="post-meta"><a href="blog-single-carousel.html#comments"><i class="fe-icon-message-square"></i>44</a><span><i class="fe-icon-clock"></i>May 17, 2018</span></div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- YouTube CTA-->
<section class="bg-secondary py-5">
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center">
      <div class="col-xl-6 col-md-7"><img class="d-block" src="https://via.placeholder.com/954x480" alt="YouTube Channel"></div>
      <div class="col-xl-4 col-md-5 text-center text-md-left">
        <a class="video-player-button my-3 mr-3" href="#"><i class="fe-icon-play"></i></a><span class="video-player-label text-muted">Click me to go to channel!</span>
        <h2 class="h3 mt-2">Watch us on YouTube</h2>
        <p class="text-muted d-none d-lg-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>
    </div>
  </div>
</section>

<!-- Mobile App CTA-->
<section class="bg-secondary pt-3">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 text-center text-md-left pb-4">
        <h2 class="h3 mt-2 py-3 py-lg-0">We're still in heavy development</h2>
        <p class="text-muted d-none d-lg-block pb-3">
          We're still hard at work developing the tools we're hoping will add the depth and meaning to playing Star Citizen as CIG continues
          their own work. We'll do our best to keep you all updated through our website here and our Discord server with our progress.
        </p>
      </div>
      <div class="col-md-6"><img class="d-block mx-auto" src="./assets/thm/img/mobile-app.jpg" alt="Mobile App"></div>
    </div>
  </div>
</section>
