<!-- Page Title-->
<div class="page-title d-flex" aria-label="Page title" style="background-image: url(./assets/thm/img/shop-pattern.jpg);">
  <div class="container text-left align-self-center">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="./">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a href="account-orders.html">Account</a>
        </li>
      </ol>
    </nav>
    <h1 class="page-title-heading">Login</h1>
  </div>
</div>
<!-- Page Content-->
<div class="container mb-3">
  <div class="row justify-content-center">

    <div id="loginForm" class="col-md-6 pb-5">

      <form [formGroup]="loginForm" class="wizard" (ngSubmit)="onLogin()">
        <div class="wizard-body pt-2">
          <h3 class="h5 pt-4 pb-2">Login using form below</h3>

          <!-- email -->
          <div class="input-group form-group">
            <div class="input-group-prepend"><span class="input-group-text"><i class="fe-icon-mail"></i></span></div>
            <input class="form-control" formControlName="email" type="email">
            <div class="invalid-feedback">Please enter your email!</div>
          </div>

          <!-- password -->
          <div class="input-group form-group">
            <div class="input-group-prepend"><span class="input-group-text"><i class="fe-icon-lock"></i></span></div>
            <input class="form-control" type="password" formControlName="password" placeholder="Password">
            <div class="invalid-feedback">Please enter valid password!</div>
          </div>

          <div class="wizard-footer text-right">
            <p class="text-danger text-sm" *ngIf="loginError">
              Unable to log you in with that email and password.
            </p>
            <button class="btn btn-primary" type="submit" [disabled]="loginForm.invalid || isLogin">Login</button>
          </div>
        </div>
      </form>
      <br />
      <p class="text-center">
        <a href="./register">Register a new account</a>
      </p>
    </div>
  </div>
</div>
