import { Component, OnInit } from '@angular/core';
import { RSICitizen } from '../../../_models/rsiCitizen';
import { RegisterResult } from '../../../_models/registerResponse';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { SCTMApiService } from '../../../_services/sctmApi.service';
import { TokenStorageService } from '../../../_services/token-storage.service';
import { ActivatedRoute } from '@angular/router';
import { MustMatch } from '../../../_services/MustMatch';
import { LoginResult } from '../../../_models/loginResult';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {

  // querystrings
  email: string;
  checkEmail: string;
  org: string;

  registerForm: FormGroup;
  isLoading = true;

  rsiCitizen: RSICitizen;

  emailForm: FormGroup = new FormGroup({});
  passwordForm: FormGroup = new FormGroup({});

  registerBody: RegisterObject;
  isRegistering: boolean;
  registerResult: RegisterResult;

  isConfirming = false;
  confirmSuccess = false;
  confirmError = false;


  constructor(private formBuilder: FormBuilder, private api: SCTMApiService, private tokenStorage: TokenStorageService, private router: ActivatedRoute) {
  }

  ngOnInit(): void {
    

    this.email = this.router.snapshot.queryParamMap.get('email');
    this.org = this.router.snapshot.queryParamMap.get('org');

    this.buildRegisterForm();
    if (this.email !== null && this.email.trim().length > 0) {
      this.registerForm.controls['email'].setValue(this.email);
      this.getRegisterProgress().then().finally(() => { this.isLoading = false; });
    } else {
      this.isLoading = false;
    }
  }
  get fr() { return this.registerForm.controls; }

  buildRegisterForm() {
    this.registerForm = this.formBuilder.group({
      rsiHandle: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        this.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        this.patternValidator(/[a-z]/, { hasSmallCase: true }),
        this.patternValidator(/[!@#$%^&*()_\-+={[}\]:;"'<,>.?/]/, { hasSpecialCharacters: true }),
      ]],
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });


    if (this.email !== null && this.email.trim().length > 0) {
      this.registerForm.controls['email'].setValue(this.email);
    }

  }

  getOrg(identifier: string) {

  }

  registerSubmit() {
    this.isRegistering = true;

    this.registerResult = null;

    this.registerBody = {
      email: this.registerForm.controls['email'].value,
      password: this.registerForm.controls['password'].value,
      rsiHandle: this.registerForm.controls['rsiHandle'].value,
      source: this.org || 'website'
    };

    this.api.Register_POST(this.registerBody.email, this.registerBody.password, this.registerBody.rsiHandle, null, null).toPromise().then((response: RegisterResult) => {
      this.registerResult = response;
      console.log(response);
    }, error => {
      console.error(error);
    }).finally(() => { this.isRegistering = false; })

  }

  rsiHandleFound($event: RSICitizen) {
    if ($event === null) return;

    this.rsiCitizen = $event;
    this.registerForm.controls['rsiHandle'].setValue(this.rsiCitizen.handle);
  }

  async getRegisterProgress() {
    console.log("Checking registration progress for " + this.email);
    this.api.Register_POST(this.email, "p@ssw0rd", "", null, null).toPromise().then((response: RegisterResult) => {
      console.log(response);
      if (response.status === "CONFIRMED") {
        // registered user
        console.log("User already registered");
        this.registerResult = response;
      }
      else if (response.status === "UNCONFIRMED") {
        // continue registration
        console.log("Unconfirmed user");
        this.registerResult = response;
      }
      else {
        // new registration
        console.log("new user");
      }
    }, error => {
      console.error("error, new user");
    });
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }


  confirmSubmit() {
    this.isConfirming = true;

    this.confirmSuccess = false;
    this.confirmError = false;

    this.api.Verify_POST(this.registerForm.controls["email"].value, null, null).toPromise().then(response => {
      this.confirmSuccess = true;

    }, error => {
      this.confirmError = true;
    })
      .finally(() => {
        this.isConfirming = false;
      });
  }
}

class RegisterObject {
  email: string;
  rsiHandle: string;
  password: string;
  source: string;
}
